import { Api } from '../resources/Api';

export const GET_CHAINS_SUCCESS = 'GET_CHAINS_SUCCESS';
export const GET_CHAINS_ERROR = 'GET_CHAINS_ERROR';
export const APPROVE_ALL_RESTAURANTS_SUCCESS = 'APPROVE_ALL_RESTAURANTS_SUCCESS';
export const APPROVE_ALL_RESTAURANTS_ERROR = 'APPROVE_ALL_RESTAURANTS_ERROR';
export const DISAPPROVE_ALL_RESTAURANTS_SUCCESS = 'DISAPPROVE_ALL_RESTAURANTS_SUCCESS';
export const DISAPPROVE_ALL_RESTAURANTS_ERROR = 'DISAPPROVE_ALL_RESTAURANTS_ERROR';

export const getChains = () => {
    return async (dispatch) => {
        try {
            const response = await Api.getChains();
            if (response.success) {
                dispatch({ type: GET_CHAINS_SUCCESS, chains: response.data.data });
            } else {
                dispatch({ type: GET_CHAINS_ERROR, error: response.error });
            }
        } catch (error) {
            dispatch({ type: GET_CHAINS_ERROR, error });
        }
    };
};

export const approveAllRestaurants = (chainId) => {
    return async (dispatch) => {
        try {
            const response = await Api.approveAllRestaurants(chainId);
            if (response.success) {
                dispatch({ type: APPROVE_ALL_RESTAURANTS_SUCCESS, chainId, restaurantsCount: response.restaurantsCount });
            } else {
                console.error(`failed to approve restaurants for chain ${chainId}`, response.error);
                dispatch({ type: APPROVE_ALL_RESTAURANTS_ERROR, error: response.error });
            }
        } catch (error) {
            console.error(`failed to approve restaurants for chain ${chainId}`, error);
            dispatch({ type: APPROVE_ALL_RESTAURANTS_ERROR, error });
        }
    };
};

export const disapproveAllRestaurants = (chainId) => {
    return async (dispatch) => {
        try {
            const response = await Api.disapproveAllRestaurants(chainId);
            if (response.success) {
                dispatch({ type: DISAPPROVE_ALL_RESTAURANTS_SUCCESS, chainId, restaurantsCount: response.restaurantsCount });
            } else {
                console.error(`failed to disapprove restaurants for chain ${chainId}`, response.error);
                dispatch({ type: DISAPPROVE_ALL_RESTAURANTS_ERROR, error: response.error });
            }
        } catch (error) {
            console.error(`failed to disapprove restaurants for chain ${chainId}`, error);
            dispatch({ type: DISAPPROVE_ALL_RESTAURANTS_ERROR, error });
        }
    };
};